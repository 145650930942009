import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDateForDatePicker } from '../../util/dates';
import { ToastNotification } from '../ToastNotification';
import DatePicker from '../Form/DatePicker';
import TextBox from '../Form/TextBox';
import Button from '../Form/Button';

const LbpAddActivityForm = (props) => {
  const { onSubmit } = props;
  const [lbpActivityImages, setlbpActivityImages] = useState([]);
  const [lbpActivityImageURL, setlbpActivityImageURL] = useState([]);
  const [maxImageError, setmaxImageError ] = useState(false);
  const [segmentedControlValue, setSegmentedControlValue] = useState('Elective');
  const [inputHoursValue, setInputHoursValue] = useState(0);
  const segmentedControlOnChange = (e) => {
    setSegmentedControlValue(e.currentTarget.value);
  };

  const increaseHoursValue = () => {
    setInputHoursValue(inputHoursValue + 1);
  };

  const decreaseHoursValue = () => {
    if (inputHoursValue > 0) {
      setInputHoursValue(inputHoursValue - 1);
    }
  };

  const onImageChange = (e) => {
    try{
      if (e.target.files.length <= 5) {
        setmaxImageError(false);
        setlbpActivityImages([...e.target.files]);
      } else {
        setmaxImageError(true);
      }
    } catch (error){
      ToastNotification(
        'failure',
        `${error}`,
        3000,
        () => {},
      );   
    }
  };

  useEffect(() => {
    const newlbpActivityImageURL = [];
    lbpActivityImages.forEach(image => newlbpActivityImageURL.push(URL.createObjectURL(image)));
    setlbpActivityImageURL(newlbpActivityImageURL);
  }, [lbpActivityImages]);

  const onFormSubmit = (event) => {
    onSubmit(event, lbpActivityImages, lbpActivityImageURL);
  };

  const removedUploadedImage = (imageIndex) => {
    const filteredActivityImage = lbpActivityImages.filter((image) => image !== lbpActivityImages[imageIndex]);
    setlbpActivityImages(filteredActivityImage);
  };

  return (
    <form onSubmit={onFormSubmit} encType="multipart/form-data">
      <div className="article-page__wrapper">
        <p className="small-text small-text--alt">Activity type</p>
        <div className="segmented-control">
          <input
            type="radio"
            name="segmented-control"
            value="Elective"
            id="Elective"
            checked={segmentedControlValue === 'Elective'}
            onChange={segmentedControlOnChange}
          />
          <label htmlFor="Elective">Elective</label>

          <input
            type="radio"
            name="segmented-control"
            value="On-the-job"
            id="On-the-job"
            checked={segmentedControlValue === 'On-the-job'}
            onChange={segmentedControlOnChange}
          />
          <label htmlFor="On-the-job">On-the-job</label>
        </div>
      </div>
      <TextBox label="Activity Title" />
      {segmentedControlValue === 'Elective' && (
        <TextBox label="Activity Details" rows="7" />
      )}

      {segmentedControlValue === 'On-the-job' && (
        <>
          <TextBox label="Describe what you did?" rows="7" />
          <TextBox label="What did you learn?" rows="7" />
        </>
      )}

      <DatePicker value={formatDateForDatePicker(new Date())} label="Date completed" />

      {segmentedControlValue === 'Elective' && (
        <div className="input-form input-form--counter">
          <Button
            type="button"
            text="-"
            isDisabled={inputHoursValue === 0}
            handleSubmit={() => decreaseHoursValue()}
          />
          <div className="input-form__wrapper">
            <label>Hours Spent</label>
            <input className="form-input" value={inputHoursValue} readOnly />
          </div>
          <Button
            type="button"
            text="+"
            handleSubmit={() => increaseHoursValue()}
          />
        </div>
      )}

      <div className="input-form input-form--upload">
        <p>Add a photo</p>
        <span>Maximum 5 photos</span>
        <label>
          <input
            type="file"
            accept="image/png, image/jpeg"
            multiple
            onChange={onImageChange}
          />
          <div className="input-form__custom-file">
            <div className="add-photo-btn">
              <i className="fas fa-image"></i>
            </div>
            <span>Add a photo</span>
          </div>
        </label>
        { maxImageError && <p className="error-message error-message--align-left"><i className="fas fa-exclamation"></i>Maximum 5 photos</p>  }
        { lbpActivityImages.length !== 0 && <div>
          <div className="input-form--uploaded__images">
            {lbpActivityImageURL.map((imageSrc, index) =>
              <div key={index}>
                <button type="button" onClick={() => removedUploadedImage(index)}><i className="fas fa-times"></i></button>
                <img src={imageSrc} />
              </div>
            )}
          </div>
        </div>}
      </div>
      
      <Button isDisabled = {!inputHoursValue >0 } text="Save Activity" />
    </form>
  );
};

LbpAddActivityForm.propTypes = {
  onSubmit: PropTypes.func,
};

LbpAddActivityForm.defaultProps = {
  onSubmit: () => {},
};

export default LbpAddActivityForm;
